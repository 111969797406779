






































































































import { mapState } from 'vuex';
import { getUserTransactionsSummary } from '@/api/ClientInfo';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import { DataTableHeader } from 'vuetify';
import { DateTime } from 'luxon';
import {
  ITransferSummaryDataRequest,
  IUserTransactionsSummaryItem,
  TransactionsTypeNaming,
  TransactionsTypes,
  UserTransactionsParamsProps
} from '@/api/schema';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { EUserManagementTabsKeys } from '@/views/DashboardViews/user-management/UserManagementTabsView.vue';

const defaultDates = {
  from: DateTime.now().startOf('year').toFormat('yyyy-LL-dd'),
  to: DateTime.now().toFormat('yyyy-LL-dd')
};

interface ITransactionSummaryItem extends IUserTransactionsSummaryItem {
  name: string;
  transactionType?: TransactionsTypes;
  tooltip?: string;
  disableRedirect?: boolean;
  groupItems?: ITransactionSummaryItem[];
  hide?: boolean;
}

export default {
  name: 'UserManagementTransactionSummaryTab',
  components: { Tooltip, VExtendedDataPicker },
  data(): any {
    return {
      loading: false,
      dates: { ...defaultDates },
      summaryData: [],
      expanded: [],
      widthColumnParams: ['28%', '28%', '28%', '16%']
    };
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    operatorUserId: {
      type: String,
      default: (): string => null
    },
    operatorUserCreatedAt: {
      type: String,
      default: (): string => ''
    }
  },
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapState('OperatorPayments', ['balance']),
    loadingUserDetails(): boolean {
      return !(this.operatorUserId || this.operatorUserCreatedAt);
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: 'Transaction Type',
          value: 'name',
          width: this.widthColumnParams[0]
        },
        {
          text: 'Quantity',
          value: 'count',
          width: this.widthColumnParams[1]
        },
        {
          text: 'Total Amount',
          value: 'amount',
          width: this.widthColumnParams[2]
        },
        {
          text: 'Action',
          value: 'actions',
          width: this.widthColumnParams[3]
        }
      ];
    },
    transactionSummaryItems(): ITransactionSummaryItem[] {
      const defaultValues: IUserTransactionsSummaryItem = {
        amount: 0,
        amountUsd: 0,
        count: 0,
        fee: 0,
        feeUsd: 0
      };

      const getDataByType = (
        type: keyof typeof TransactionsTypes
      ): IUserTransactionsSummaryItem => {
        return (
          this.summaryData?.find((item) => item.type === type) ||
          defaultValues
        );
      };

      const getGroupSumData = (
        selectedTypes: (keyof typeof TransactionsTypes)[]
      ): IUserTransactionsSummaryItem => {
        return this.summaryData?.reduce(
          (
            result: IUserTransactionsSummaryItem,
            obj: IUserTransactionsSummaryItem
          ) => {
            if (selectedTypes.includes(obj.type)) {
              result.amount += obj.amount;
              result.amountUsd += obj.amountUsd;
              result.count += obj.count;
              result.fee += obj.fee;
              result.feeUsd += obj.feeUsd;
            }
            return result;
          },
          { ...defaultValues }
        );
      };

      const result: ITransactionSummaryItem[] = [
        {
          name: TransactionsTypeNaming[TransactionsTypes.BETTING_REWARD],
          ...getGroupSumData([
            TransactionsTypes.BETTING_REWARD,
            TransactionsTypes.BETTING_REWARD_COPIED
          ]),
          groupItems: [
            {
              name: TransactionsTypeNaming[TransactionsTypes.BETTING_REWARD],
              ...getDataByType(TransactionsTypes.BETTING_REWARD)
            },
            {
              name: TransactionsTypeNaming[
                TransactionsTypes.BETTING_REWARD_COPIED
              ],
              ...getDataByType(TransactionsTypes.BETTING_REWARD_COPIED)
            }
          ]
        },
        {
          name: TransactionsTypeNaming[TransactionsTypes.BETTING_ROLLBACK],
          ...getGroupSumData([
            TransactionsTypes.BETTING_ROLLBACK,
            TransactionsTypes.BETTING_ROLLBACK_COPIED
          ]),
          groupItems: [
            {
              name: TransactionsTypeNaming[
                TransactionsTypes.BETTING_ROLLBACK
              ],
              ...getDataByType(TransactionsTypes.BETTING_ROLLBACK)
            },
            {
              name: TransactionsTypeNaming[
                TransactionsTypes.BETTING_ROLLBACK_COPIED
              ],
              ...getDataByType(TransactionsTypes.BETTING_ROLLBACK_COPIED)
            }
          ]
        },
        {
          name: TransactionsTypeNaming[TransactionsTypes.STAKING_REWARD],
          transactionType: TransactionsTypes.STAKING_REWARD,
          ...getDataByType(TransactionsTypes.STAKING_REWARD)
        },
        {
          name: TransactionsTypeNaming[TransactionsTypes.STAKE],
          transactionType: TransactionsTypes.STAKE,
          ...getDataByType(TransactionsTypes.STAKE)
        },
        {
          name: TransactionsTypeNaming[TransactionsTypes.UNSTAKE],
          transactionType: TransactionsTypes.UNSTAKE,
          ...getDataByType(TransactionsTypes.UNSTAKE)
        },
        {
          name: 'Promo Rewards',
          ...getGroupSumData([
            TransactionsTypes.PROMO_CAMPAIGN_REWARD,
            TransactionsTypes.CASINO_REWARD,
            TransactionsTypes.DAILY_CASHBACK,
            TransactionsTypes.WEEKLY_CASHBACK,
            TransactionsTypes.PROMO_DEPOSIT_REWARD,
            TransactionsTypes.REFERRAL_P2E_REWARD,
            TransactionsTypes.REFERRAL_H2E_REWARD,
            TransactionsTypes.REFERRAL_REGISTRATION_REWARD
          ]),
          groupItems: [
            {
              name: TransactionsTypeNaming[
                TransactionsTypes.PROMO_CAMPAIGN_REWARD
              ],
              transactionType: TransactionsTypes.PROMO_CAMPAIGN_REWARD,
              ...getDataByType(TransactionsTypes.PROMO_CAMPAIGN_REWARD)
            },
            {
              name: TransactionsTypeNaming[TransactionsTypes.CASINO_REWARD],
              transactionType: TransactionsTypes.CASINO_REWARD,
              ...getDataByType(TransactionsTypes.CASINO_REWARD)
            },
            {
              name: TransactionsTypeNaming[TransactionsTypes.DAILY_CASHBACK],
              transactionType: TransactionsTypes.DAILY_CASHBACK,
              ...getDataByType(TransactionsTypes.DAILY_CASHBACK)
            },
            {
              name: TransactionsTypeNaming[TransactionsTypes.WEEKLY_CASHBACK],
              transactionType: TransactionsTypes.WEEKLY_CASHBACK,
              ...getDataByType(TransactionsTypes.WEEKLY_CASHBACK)
            },
            {
              name: TransactionsTypeNaming[
                TransactionsTypes.PROMO_DEPOSIT_REWARD
              ],
              transactionType: TransactionsTypes.PROMO_DEPOSIT_REWARD,
              ...getDataByType(TransactionsTypes.PROMO_DEPOSIT_REWARD)
            },
            {
              name: TransactionsTypeNaming[
                TransactionsTypes.REFERRAL_P2E_REWARD
              ],
              transactionType: TransactionsTypes.REFERRAL_P2E_REWARD,
              ...getDataByType(TransactionsTypes.REFERRAL_P2E_REWARD)
            },
            {
              name: TransactionsTypeNaming[
                TransactionsTypes.REFERRAL_H2E_REWARD
              ],
              transactionType: TransactionsTypes.REFERRAL_H2E_REWARD,
              ...getDataByType(TransactionsTypes.REFERRAL_H2E_REWARD)
            },
            {
              name: TransactionsTypeNaming[
                TransactionsTypes.REFERRAL_REGISTRATION_REWARD
              ],
              transactionType: TransactionsTypes.REFERRAL_REGISTRATION_REWARD,
              ...getDataByType(TransactionsTypes.REFERRAL_REGISTRATION_REWARD)
            }
          ]
        },
        {
          name: 'Deposit',
          ...getGroupSumData([
            TransactionsTypes.DEPOSIT,
            TransactionsTypes.CRYPTO_DEPOSIT,
            TransactionsTypes.LIQUIDITY_DEPOSIT,
            TransactionsTypes.LIQUIDITY_DEPOSIT_ROLLBACK
          ]),
          groupItems: [
            {
              name: TransactionsTypeNaming[TransactionsTypes.DEPOSIT],
              transactionType: TransactionsTypes.DEPOSIT,
              ...getDataByType(TransactionsTypes.DEPOSIT)
            },
            {
              name: TransactionsTypeNaming[TransactionsTypes.CRYPTO_DEPOSIT],
              transactionType: TransactionsTypes.CRYPTO_DEPOSIT,
              ...getDataByType(TransactionsTypes.CRYPTO_DEPOSIT)
            },
            {
              name: TransactionsTypeNaming[
                TransactionsTypes.LIQUIDITY_DEPOSIT
              ],
              transactionType: TransactionsTypes.LIQUIDITY_DEPOSIT,
              ...getDataByType(TransactionsTypes.LIQUIDITY_DEPOSIT)
            },
            {
              name: 'Rollback Deposit for Liquidity',
              transactionType: TransactionsTypes.LIQUIDITY_DEPOSIT_ROLLBACK,
              ...getDataByType(TransactionsTypes.LIQUIDITY_DEPOSIT_ROLLBACK)
            }
          ]
        },
        {
          name: 'Withdraw',
          ...getGroupSumData([
            TransactionsTypes.WITHDRAW,
            TransactionsTypes.CRYPTO_WITHDRAW,
            TransactionsTypes.LIQUIDITY_WITHDRAW
          ]),
          groupItems: [
            {
              name: TransactionsTypeNaming[TransactionsTypes.WITHDRAW],
              transactionType: TransactionsTypes.WITHDRAW,
              ...getDataByType(TransactionsTypes.WITHDRAW)
            },
            {
              name: TransactionsTypeNaming[TransactionsTypes.CRYPTO_WITHDRAW],
              transactionType: TransactionsTypes.CRYPTO_WITHDRAW,
              ...getDataByType(TransactionsTypes.CRYPTO_WITHDRAW)
            },
            {
              name: TransactionsTypeNaming[
                TransactionsTypes.LIQUIDITY_WITHDRAW
              ],
              transactionType: TransactionsTypes.LIQUIDITY_WITHDRAW,
              ...getDataByType(TransactionsTypes.LIQUIDITY_WITHDRAW)
            }
          ]
        },
        {
          name: 'Transfer is equal',
          ...getGroupSumData([
            TransactionsTypes.TRANSFER_IN,
            TransactionsTypes.TRANSFER_OUT
          ]),
          groupItems: [
            {
              name: TransactionsTypeNaming[TransactionsTypes.TRANSFER_IN],
              transactionType: TransactionsTypes.TRANSFER_IN,
              tooltip:
                'Transactions from Master wallet to balance of current user',
              ...getDataByType(TransactionsTypes.TRANSFER_IN)
            },
            {
              name: TransactionsTypeNaming[TransactionsTypes.TRANSFER_OUT],
              transactionType: TransactionsTypes.TRANSFER_OUT,
              tooltip:
                'Transactions from balance of current user to Master wallet',
              ...getDataByType(TransactionsTypes.TRANSFER_OUT)
            }
          ]
        },
        {
          name: 'Token Sale',
          ...getGroupSumData([
            TransactionsTypes.TOKEN_SALE_PURCHASE,
            TransactionsTypes.DISTRIBUTION
          ]),
          groupItems: [
            {
              name: TransactionsTypeNaming[
                TransactionsTypes.TOKEN_SALE_PURCHASE
              ],
              transactionType: TransactionsTypes.TOKEN_SALE_PURCHASE,
              ...getDataByType(TransactionsTypes.TOKEN_SALE_PURCHASE)
            },
            {
              name: TransactionsTypeNaming[TransactionsTypes.DISTRIBUTION],
              transactionType: TransactionsTypes.DISTRIBUTION,
              ...getDataByType(TransactionsTypes.DISTRIBUTION)
            }
          ]
        },
        {
          name: TransactionsTypeNaming[TransactionsTypes.BURN],
          transactionType: TransactionsTypes.BURN,
          ...getDataByType(TransactionsTypes.BURN)
        },
        {
          name: TransactionsTypeNaming[TransactionsTypes.LOYALTY_SWAP],
          transactionType: TransactionsTypes.LOYALTY_SWAP,
          ...getDataByType(TransactionsTypes.LOYALTY_SWAP),
          hide: !this.balance.loyaltySwapFeeActive
        }
      ];

      return result.filter((item) => !item.hide);
    }
  },
  watch: {
    operatorUserId: {
      handler(newId: string | null): void {
        if (newId) {
          this.checkUserCreatedDate();
          this.getTransactionsSummary();
        }
      },
      immediate: true
    }
  },
  methods: {
    getTransactionsSummary(): Promise<unknown> {
      this.loading = true;

      const { from, to } = this.dates;
      const searchParams: ITransferSummaryDataRequest = {
        startDate: from,
        endDate: to
      };

      return getUserTransactionsSummary(this.userId, searchParams)
        .then((data: IUserTransactionsSummaryItem[]): void => {
          this.summaryData = data;
        })
        .catch(errorToastMessage)
        .finally((): void => {
          this.loading = false;
        });
    },
    checkUserCreatedDate(): void {
      const date = this.operatorUserCreatedAt;

      if (date) {
        const userCreatedDate = DateTime.fromFormat(
          date,
          'yyyy-MM-dd HH:mm:ss'
        );

        this.dates.from = userCreatedDate.toFormat('yyyy-MM-dd');
      } else {
        this.dates.from = defaultDates.from;
      }
    },
    toggleExpandedRow(item: ITransactionSummaryItem): void {
      if (this.isExpanded(item)) {
        this.expanded.splice(this.expanded.indexOf(item), 1);
      } else {
        this.expanded.push(item);
      }
    },
    isExpanded(item: ITransactionSummaryItem): boolean {
      return this.expanded.indexOf(item) !== -1;
    },
    transactionTypeRedirect(
      transactionType: keyof typeof TransactionsTypes
    ): void {
      this.$emit('changeTab', EUserManagementTabsKeys.TRANSACTIONS);

      this.$router.replace({
        name: this.isSuperAdmin
          ? 'account-operator-token-user-management'
          : 'user-management',
        params: {
          paramsProps: {
            type: transactionType,
            dates: this.dates
          } as UserTransactionsParamsProps
        },
        hash: `#${EUserManagementTabsKeys.TRANSACTIONS}`
      } as any);
    },
    onDateChange(): void {
      this.getTransactionsSummary();
    }
  }
};
